import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Button from './button'
import CardList from './cardlist'

export default function Division({
  title, background, path, external, translationKey,
}) {
  const data = useStaticQuery(graphql`
    query DivisionImages {
        images: allFile(filter: { relativeDirectory: { eq: "divisions" } } ) {
            nodes {
                id
                childImageSharp {
                    fluid {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }
  `)

  const image = data.images.nodes.find((n) => n.childImageSharp.fluid.src.includes(background))

  return (
      <div className="division-card">
          <div className="division-card-wrapper">
              <div className="division-card-inner division-card-front">
                  <div className="division-card-img-wrapper">
                      <Img className="division-card-img" fluid={image.childImageSharp.fluid} objectFit="cover" />
                      <div className="division-card-img-overlay" />
                  </div>
                  <div className="division-card-title">
                      <p>
                          {title.toUpperCase()}
                      </p>
                  </div>
              </div>
              <div className="division-card-inner division-card-back">
                  <div className="division-card-description">
                      <CardList translationKey={translationKey} ulClassName="division-card-list" />
                  </div>
                  <div className="division-card-btn-container">
                      <Button color="white" text="details" path={path} external={external} />
                  </div>
              </div>
          </div>
      </div>
  )
}

Division.propTypes = {
  title: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  translationKey: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  external: PropTypes.bool,
}

Division.defaultProps = {
  external: undefined,
}
