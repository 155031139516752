import React from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next'

import Division from './division'

const Landing = () => {
  const { t } = useI18next()

  return (
      <div id="landing">
          <div id="welcome">
              <div id="welcome-logo-container">
                  <div id="welcome-logo" />
                  <p id="welcome-text">{t('motto').toUpperCase()}</p>
              </div>
              <div id="welcome-svg" />
              <div id="welcome-veil" />
          </div>
          <div id="divisions">
              <div id="divisions-container">
                  <Division title={t('division-iiot')} background="bg-division-iot" path="/divisions/iiot" translationKey="division-iiot-card-list" />
                  <Division title={t('division-telecommunication')} background="bg-division-telecom" path="/divisions/sga" translationKey="division-telecommunication-card-list" />
                  <Division title={t('division-speech')} background="bg-division-speech" path="http://speechtex.com/" external translationKey="division-speech-card-list" />
              </div>
          </div>
      </div>
  )
}

export default Landing
